<form appSrollOnError [formGroup]="customerInformationForm" class="ir-form" (ngSubmit)="onSubmit()">
  <div class="section-card">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.GeneralInformation" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field appearance="standard" class="col-3" [ngClass]="
            customerInformationForm.get('startup')?.value === null && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="startup">{{
            "IR_FORM.Startup" | translate
            }}</mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="startup" name="startup">
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
              }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!customerInformationForm.get('startup').valid">
            {{
            customerInformationForm.get("startup").errors
            | getErrorMessage: translations.Startup
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3" [ngClass]="
            customerInformationForm.get('hasExport')?.value === null && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="hasExport">{{
            "IR_FORM.Export" | translate
            }}</mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="hasExport"
            name="hasExport">
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
              }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!customerInformationForm.get('hasExport').valid">
            {{
            customerInformationForm.get("hasExport").errors
            | getErrorMessage: translations.Export
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3" [ngClass]="
            !customerInformationForm.get('productionType')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="productionType">
            {{ "IR_FORM.Tipology" | translate }}
          </mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="productionType"
            name="productionType">
            <mat-option value="P">{{
              "IR_FORM.Production" | translate
              }}</mat-option>
            <mat-option value="C">{{
              "IR_FORM.Commercial" | translate
              }}</mat-option>
            <mat-option value="S">{{
              "IR_FORM.Services" | translate
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!customerInformationForm.get('productionType').valid">
            {{
            customerInformationForm.get("productionType").errors
            | getErrorMessage: translations.Tipology
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3" formGroupName="commerceSector" [ngClass]="
            !customerInformationForm.get('commerceSector.idCommerceSector')
              ?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="idCommerceSector">
            {{ "IR_FORM.CommerceSector" | translate }}
          </mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="idCommerceSector"
            name="idCommerceSector">
            <mat-option *ngFor="let sector of commerceSectors" [value]="sector.idCommerceSector">
              {{ sector.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
              !customerInformationForm.controls.commerceSector.get(
                'idCommerceSector'
              ).valid
            ">
            {{
            customerInformationForm.controls.commerceSector.get(
            "idCommerceSector"
            ).errors | getErrorMessage: translations.CommerceSector
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="row">
        <mat-form-field
          appearance="standard"
          class="col"
          [ngClass]="
            !customerInformationForm.get('productionDescription')?.value &&
            isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="productionDescription">
            {{ "IR_FORM.DescriptionOfProduction" | translate }}
          </mat-label>
          <textarea
            matInput
            formControlName="productionDescription"
            name="productionDescription"
          ></textarea>
          <mat-error
            *ngIf="!customerInformationForm.get('productionDescription').valid"
          >
            {{
              customerInformationForm.get("productionDescription").errors
                | getErrorMessage: translations.DescriptionOfProduction
            }}
          </mat-error>
        </mat-form-field>
      </div> -->
    </div>
  </div>
  <div class="section-card mt-30">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.TurnoverAndImportData" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div formArrayName="revenueCustomerList">
        <div class="row" *ngFor="let revenue of revenuesCustomer.controls; let i = index" [formGroupName]="i">
          <mat-form-field appearance="standard" class="col" [ngClass]="
              !revenue.get('revenueCustomer')?.value && isIsp
                ? 'red-field-for-lab-manager'
                : ''
            ">
            <mat-label for="revenueCustomer">
              {{
              getYearLabel(revenue.controls.year.value, 'company') | translate
              }}
            </mat-label>
            <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
              formControlName="revenueCustomer" name="revenueCustomer" />
            <mat-error *ngIf="!revenue.get('revenueCustomer').valid">
              {{
              revenue.get("revenueCustomer").errors
              | getErrorMessage: translations.RevenueCustomer
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="col">
            <mat-label for="revenueExport">
              {{
              getYearLabel(revenue.controls.year.value, 'export') | translate
              }}
            </mat-label>
            <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
              formControlName="revenueExport" name="revenueExport" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="col">
            <mat-label for="revenueImport">
              {{
              getYearLabel(revenue.controls.year.value, 'import') | translate
              }}
            </mat-label>
            <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
              formControlName="revenueImport" name="revenueImport" />
          </mat-form-field>
          <!-- <mat-label for="revenueCustomer">
              {{
              "IR_FORM.CompanyTurnover"
              | translate: { year: revenue.controls.year.value }
              }}
            </mat-label>
            <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
              formControlName="revenueCustomer" name="revenueCustomer" />
            <mat-error *ngIf="!revenue.get('revenueCustomer').valid">
              {{
              revenue.get("revenueCustomer").errors
              | getErrorMessage: translations.RevenueCustomer
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="col">
            <mat-label for="revenueExport">
              {{
              "IR_FORM.ExportTurnover"
              | translate: { year: revenue.controls.year.value }
              }}
            </mat-label>
            <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
              formControlName="revenueExport" name="revenueExport" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="col">
            <mat-label for="revenueImport">
              {{
              "IR_FORM.ImportTurnover"
              | translate: { year: revenue.controls.year.value }
              }}
            </mat-label>
            <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
              formControlName="revenueImport" name="revenueImport" />
          </mat-form-field> -->
        </div>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col" [ngClass]="
            !customerInformationForm.get('countriesOfImport')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="countriesOfImport">
            {{ "IR_FORM.ImportCountriesList" | translate }}
          </mat-label>
          <mat-select formControlName="countriesOfImport" multiple disableOptionCentering
            (openedChange)="filter.focus(); filter.value = ''" (closed)="filter.value = ''">
            <div class="select-global-filter">
              <input #filter matInput type="text" [placeholder]="'COMMON.Search...' | translate" />
              <button matSuffix *ngIf="filter.value !== ''" class="only-icon-button" type="button"
                (click)="filter.value = null">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <mat-option [ngStyle]="{
                display:
                  (countries | filterSelect: filter.value:'country').includes(
                    country
                  ) || filter.value === ''
                    ? 'flex'
                    : 'none'
              }" *ngFor="let country of countries" [value]="country.idCountry">
              {{ country.country }}
            </mat-option>
            <div *ngIf="
                !(countries | filterSelect: filter.value:'country')?.length
              " class="select-global-filter-no-result">
              {{ "COMMON.NoResult" | translate }}
            </div>
          </mat-select>
          <!-- <input
            matInput
            formControlName="countriesOfImport"
            type="text"
            name="countriesOfImport"
          /> -->
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="section-card mt-30">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.ExportOrganization" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field appearance="standard" class="col-3" [ngClass]="
            customerInformationForm.get('hasExpDedicatedResource')?.value ===
              null && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="hasExpDedicatedResource">
            {{ "IR_FORM.ExportResources" | translate }}
          </mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="hasExpDedicatedResource"
            name="hasExpDedicatedResource">
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
              }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="
              !customerInformationForm.get('hasExpDedicatedResource').valid
            ">
            {{
            customerInformationForm.get("hasExpDedicatedResource").errors
            | getErrorMessage: translations.ExportResources
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3"
          *ngIf="customerInformationForm.get('hasExpDedicatedResource').value" [ngClass]="
            customerInformationForm.get('resourceTrained')?.value === null &&
            isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="resourceTrained">
            {{ "IR_FORM.Formed" | translate }}
          </mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="resourceTrained"
            name="resourceTrained">
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
              }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!customerInformationForm.get('resourceTrained').valid">
            {{
            customerInformationForm.get("resourceTrained").errors
            | getErrorMessage: translations.Formed
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-6"
          *ngIf="customerInformationForm.get('hasExpDedicatedResource').value">
          <mat-label for="foreignLanguageKnown">
            {{ "IR_FORM.ForeignLanguages" | translate }}
          </mat-label>
          <input matInput formControlName="foreignLanguageKnown" type="text" name="foreignLanguageKnown" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col">
          <mat-label for="productCertification">
            {{ "IR_FORM.ProductCertification" | translate }}
          </mat-label>
          <input matInput formControlName="productCertification" type="text" name="productCertification" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col">
          <mat-label for="processCertification">
            {{ "IR_FORM.ProcessCertification" | translate }}
          </mat-label>
          <input matInput formControlName="processCertification" type="text" name="processCertification" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col">
          <mat-label for="sectorProductAssociation">
            {{ "IR_FORM.MainIndustryProductAssociations" | translate }}
          </mat-label>
          <input matInput formControlName="sectorProductAssociation" type="text" name="sectorProductAssociation" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="section-card mt-30">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.CurrentExportStrategies" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field appearance="standard" class="col" [ngClass]="
            !customerInformationForm.get('exportStrategyMarketAdopted')
              ?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="exportStrategyMarketAdopted">
            {{ "IR_FORM.MarketSelectionStrategy" | translate }}
          </mat-label>
          <textarea matInput formControlName="exportStrategyMarketAdopted" type="text"
            name="exportStrategyMarketAdopted"></textarea>
          <mat-error *ngIf="customerInformationForm.get('exportStrategyMarketAdopted').invalid">
            {{
            customerInformationForm.get("exportStrategyMarketAdopted").errors
            | getErrorMessage: translations.MarketSelectionStrategy
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col" [ngClass]="
            !customerInformationForm.get('exportStrategyChannelAdopted')
              ?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="exportStrategyChannelAdopted">
            {{ "IR_FORM.DistributionChannelsSelectionStrategy" | translate }}
          </mat-label>
          <textarea matInput formControlName="exportStrategyChannelAdopted" type="text"
            name="exportStrategyChannelAdopted"></textarea>
          <mat-error *ngIf="customerInformationForm.get('exportStrategyChannelAdopted').invalid">
            {{
            customerInformationForm.get("exportStrategyChannelAdopted").errors
            | getErrorMessage: translations.DistributionChannelsSelectionStrategy
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col" [ngClass]="
            !customerInformationForm.get('exportStrategyScoutingClients')
              ?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="exportStrategyScoutingClients">
            {{ "IR_FORM.SearchNewCustomersDistributors" | translate }}
          </mat-label>
          <textarea matInput formControlName="exportStrategyScoutingClients" type="exportStrategyScoutingClients"
            name="exportStrategyScoutingClients"></textarea>
          <mat-error *ngIf="customerInformationForm.get('exportStrategyScoutingClients').invalid">
            {{
            customerInformationForm.get("exportStrategyScoutingClients").errors
            | getErrorMessage: translations.SearchNewCustomersDistributors
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="section-card mt-30" *ngIf="functionalities.isp.create || functionalities.isp.edit">
    <div class="header">
      <!-- <div> -->
      <div class="title">
        <span>{{ "IR_FORM.ValueForPositioningChart" | translate }}</span>
      </div>
      <!-- <div class="description">
          <span>{{ "IR_FORM.ValueForPositioningChartDescription" | translate }}</span>
        </div>
      </div> -->
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            customerInformationForm.get('product').value
              ? ''
              : 'red-field-for-lab-manager'
          ">
          <mat-label for="product">
            {{ "IR_FORM.Product" | translate }}
          </mat-label>
          <input matInput formControlName="product" type="number" min="1" max="10" step="0.01" name="product" />
          <mat-error *ngIf="customerInformationForm.get('product').invalid">
            {{
            customerInformationForm.get("product").errors
            | getErrorMessage: translations.Product
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" [ngClass]="
            customerInformationForm.get('product').value
              ? ''
              : 'red-field-for-lab-manager'
          ">
          <mat-label for="distribution">
            {{ "IR_FORM.distribution" | translate }}
          </mat-label>
          <input matInput formControlName="distribution" type="number" min="1" max="10" step="0.01"
            name="distribution" />
          <mat-error *ngIf="customerInformationForm.get('distribution').invalid">
            {{
            customerInformationForm.get("distribution").errors
            | getErrorMessage: translations.distribution
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" [ngClass]="
            customerInformationForm.get('product').value
              ? ''
              : 'red-field-for-lab-manager'
          ">
          <mat-label for="priceSelling">
            {{ "IR_FORM.PriceSelling" | translate }}
          </mat-label>
          <input matInput formControlName="priceSelling" type="number" min="1" max="10" step="0.01"
            name="priceSelling" />
          <mat-error *ngIf="customerInformationForm.get('priceSelling').invalid">
            {{
            customerInformationForm.get("priceSelling").errors
            | getErrorMessage: translations.PriceSelling
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" [ngClass]="
            customerInformationForm.get('product').value
              ? ''
              : 'red-field-for-lab-manager'
          ">
          <mat-label for="communicationMarketing">
            {{ "IR_FORM.CommunicationMarketing" | translate }}
          </mat-label>
          <input matInput formControlName="communicationMarketing" type="number" min="1" max="10" step="0.01"
            name="communicationMarketing" />
          <mat-error *ngIf="
              customerInformationForm.get('communicationMarketing').invalid
            ">
            {{
            customerInformationForm.get("communicationMarketing").errors
            | getErrorMessage: translations.CommunicationMarketing
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="isIsp" class="section-without-card mt-50">
    <div class="title">
      <span>{{ "IR_FORM.DataSheet" | translate }}</span>
    </div>
    <!-- <div class="description">
      <span>{{ "IR_FORM.DataSheetDescription" | translate }}</span>
    </div> -->
  </div>
  <div *ngIf="isIsp" class="section-card pt-20 mt-30" formGroupName="customerTechnicalInfo">
    <div class="header h70">
      <div class="title">
        <span>{{
          "IR_FORM.NumberOfEmployeesInTheCompanyAndTheirDistribution"
          | translate
          }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-2" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.employeeProd'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="employeeProd">
            {{ "IR_FORM.Production" | translate }}
          </mat-label>
          <input matInput name="employeeProd" formControlName="employeeProd" type="number" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.employeeProd")
            ?.errors | getErrorMessage: translations.Production
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <input style="text-align: right" matInput type="text" readonly
            [value]="calculatePercentage('prod') | number: '1.0-2':undefined" />
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field class="col-2" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.employeeCommerceItaly'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="employeeCommerceItaly">
            {{ "IR_FORM.CommercialItaly" | translate }}
          </mat-label>
          <input matInput name="employeeCommerceItaly" formControlName="employeeCommerceItaly" type="number" />
          <mat-error>
            {{
            customerInformationForm.get(
            "customerTechnicalInfo.employeeCommerceItaly"
            )?.errors | getErrorMessage: translations.CommercialItaly
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <input style="text-align: right" matInput type="text" readonly
            [value]="calculatePercentage('italy') | number: '1.0-2':undefined" />
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field class="col-2" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.employeeCommerceExport'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="employeeCommerceExport">
            {{ "IR_FORM.CommercialExport" | translate }}
          </mat-label>
          <input matInput name="employeeCommerceExport" formControlName="employeeCommerceExport" type="number" />
          <mat-error>
            {{
            customerInformationForm.get(
            "customerTechnicalInfo.employeeCommerceExport"
            )?.errors | getErrorMessage: translations.CommercialExport
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <input style="text-align: right" matInput type="text" readonly
            [value]="calculatePercentage('export') | number: '1.0-2':undefined" />
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field class="col-2" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.employeeOther'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="employeeOther">
            {{ "IR_FORM.Other" | translate }}
          </mat-label>
          <input matInput name="employeeOther" formControlName="employeeOther" type="number" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.employeeOther")
            ?.errors | getErrorMessage: translations.Other
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <input style="text-align: right" matInput type="text" readonly
            [value]="calculatePercentage('other') | number: '1.0-2':undefined" />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.UnexpressedProductionCapacity" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.unexpressedProdCapacity'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="unexpressedProdCapacity">
            {{ "IR_FORM.UnexpressedProductionCapacity" | translate }}
          </mat-label>
          <input matInput name="unexpressedProdCapacity" formControlName="unexpressedProdCapacity" type="number" />
          <span matSuffix>%</span>
          <mat-error>
            {{
            customerInformationForm.get(
            "customerTechnicalInfo.unexpressedProdCapacity"
            )?.errors
            | getErrorMessage: translations.UnexpressedProductionCapacity
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.SeasonalityOfProductsServices" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            customerInformationForm.get(
              'customerTechnicalInfo.seasonalityProducts'
            )?.value === null && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="seasonalityProducts">
            {{ "IR_FORM.SeasonalityOfProductsServices" | translate }}
          </mat-label>
          <mat-select formControlName="seasonalityProducts" (selectionChange)="
              toggleDescriptionRequired('seasonalityProductsDesc', $event.value)
            ">
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
              }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
              }}</mat-option>
          </mat-select>
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.seasonalityProducts")
            ?.errors | getErrorMessage: translations.SeasonalityOfProductsServices
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="
            customerInformationForm.get(
              'customerTechnicalInfo.seasonalityProducts'
            )?.value === true
          " [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.seasonalityProductsDesc'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          " class="col-9">
          <mat-label for="seasonalityProductsDesc">
            {{ "IR_FORM.Description" | translate }}
          </mat-label>
          <input matInput name="seasonalityProductsDesc" formControlName="seasonalityProductsDesc" type="text" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.seasonalityProductsDesc")
            ?.errors | getErrorMessage: translations.Description
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.IncidenceOfTrasportCosts" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.incidenceTrasportCost'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="incidenceTrasportCost">
            {{ "IR_FORM.IncidenceOfTrasportCosts" | translate }}
          </mat-label>
          <mat-select formControlName="incidenceTrasportCost">
            <mat-option value="low">{{ "IR_FORM.Low" | translate }}</mat-option>
            <mat-option value="medium">{{
              "IR_FORM.Medium" | translate
              }}</mat-option>
            <mat-option value="high">{{
              "IR_FORM.High" | translate
              }}</mat-option>
          </mat-select>
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.incidenceTrasportCost")
            ?.errors | getErrorMessage: translations.IncidenceOfTrasportCosts
            }}
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field
          class="col-9"
          [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.incidenceTrasportCostDesc'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="incidenceTrasportCostDesc">
            {{ "IR_FORM.Description" | translate }}
          </mat-label>
          <input
            matInput
            name="incidenceTrasportCostDesc"
            formControlName="incidenceTrasportCostDesc"
            type="text"
          />
        </mat-form-field> -->
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.List" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            customerInformationForm.get('customerTechnicalInfo.existsPriceList')
              ?.value === null && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="existsPriceList">
            {{ "IR_FORM.ExistAList" | translate }}
          </mat-label>
          <mat-select formControlName="existsPriceList" (selectionChange)="
              toggleDescriptionRequired('priceListDesc', $event.value)
            ">
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
              }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
              }}</mat-option>
          </mat-select>
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.existsPriceList")
            ?.errors | getErrorMessage: translations.List
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="
            customerInformationForm.get('customerTechnicalInfo.existsPriceList')
              ?.value === true
          " [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.priceListDesc'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          " class="col-9">
          <mat-label for="priceListDesc">
            {{ "IR_FORM.DiscountDescription" | translate }}
          </mat-label>
          <input matInput name="priceListDesc" formControlName="priceListDesc" type="text" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.priceListDesc")
            ?.errors | getErrorMessage: translations.DiscountDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.PaymentSystemsToBeAdopted" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-12" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.paymentSystemDesc'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="paymentSystemDesc">
            {{ "IR_FORM.PaymentSystemsToBeAdopted" | translate }}
          </mat-label>
          <input matInput name="paymentSystemDesc" formControlName="paymentSystemDesc" type="text" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.paymentSystemDesc")
            ?.errors | getErrorMessage: translations.PaymentSystemsToBeAdopted
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.CreditProtectionMeasures" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            customerInformationForm.get(
              'customerTechnicalInfo.creditProtectionAdopted'
            )?.value === null && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="creditProtectionAdopted">
            {{
            "IR_FORM.AreMeasuresTakenToProtectCommercialCredit" | translate
            }}
          </mat-label>
          <mat-select formControlName="creditProtectionAdopted" (selectionChange)="
              toggleDescriptionRequired('creditProtectionAdoptedDesc', $event.value)
            ">
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
              }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
              }}</mat-option>
          </mat-select>
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.creditProtectionAdopted")
            ?.errors | getErrorMessage: translations.CreditProtectionMeasures
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="
            customerInformationForm.get(
              'customerTechnicalInfo.creditProtectionAdopted'
            )?.value === true
          " [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.creditProtectionAdoptedDesc'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          " class="col-9">
          <mat-label for="creditProtectionAdoptedDesc">
            {{ "IR_FORM.Description" | translate }}
          </mat-label>
          <input matInput name="creditProtectionAdoptedDesc" formControlName="creditProtectionAdoptedDesc"
            type="text" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.creditProtectionAdoptedDesc")
            ?.errors | getErrorMessage: translations.Description
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.Surrender" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-12" [ngClass]="
            !customerInformationForm.get('customerTechnicalInfo.surrenderDesc')
              ?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="surrenderDesc">
            {{ "IR_FORM.Surrender" | translate }}
          </mat-label>
          <input matInput name="surrenderDesc" formControlName="surrenderDesc" type="text" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.surrenderDesc")
            ?.errors | getErrorMessage: translations.Surrender
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.Packaging" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-12" [ngClass]="
            !customerInformationForm.get('customerTechnicalInfo.packingDesc')
              ?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="packingDesc">
            {{ "IR_FORM.Packaging" | translate }}
          </mat-label>
          <input matInput name="packingDesc" formControlName="packingDesc" type="text" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.packingDesc")
            ?.errors | getErrorMessage: translations.Packaging
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.DeliveryTimes" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-12" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.deliveryTimeDesc'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="deliveryTimeDesc">
            {{ "IR_FORM.DeliveryTimes" | translate }}
          </mat-label>
          <input matInput name="deliveryTimeDesc" formControlName="deliveryTimeDesc" type="text" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.deliveryTimeDesc")
            ?.errors | getErrorMessage: translations.DeliveryTimes
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.ValueOfReliabilityOnDeliveries" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.degreeReliability'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="degreeReliability">
            {{ "IR_FORM.Percentage" | translate }}
          </mat-label>
          <input matInput name="degreeReliability" formControlName="degreeReliability" type="number" />
          <span matSuffix>%</span>
          <mat-error>
            {{
            customerInformationForm.get(
            "customerTechnicalInfo.degreeReliability"
            )?.errors | getErrorMessage: translations.Percentage
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.AnnualTurnoverForCoverCosts" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.annualTurnoverCoverCost'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="annualTurnoverCoverCost">
            {{ "IR_FORM.Value" | translate }}
          </mat-label>
          <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
            name="annualTurnoverCoverCost" formControlName="annualTurnoverCoverCost" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.annualTurnoverCoverCost")
            ?.errors | getErrorMessage: translations.Value
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "IR_FORM.SalesTargetYear" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.annualSalesTargetValue'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="annualSalesTargetValue">
            {{ "IR_FORM.Value" | translate }}
          </mat-label>
          <input matInput currencyMask autocomplete="off" [options]="currencyDefaultConfig"
            name="annualSalesTargetValue" formControlName="annualSalesTargetValue" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.annualSalesTargetValue")
            ?.errors | getErrorMessage: translations.Value
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.annualSalesTargetQty'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="annualSalesTargetQty">
            {{ "IR_FORM.Quantity2" | translate }}
          </mat-label>
          <input matInput currencyMask autocomplete="off" name="annualSalesTargetQty"
            [options]="currencyQuantityConfigWithoutDecimal" formControlName="annualSalesTargetQty" />
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.annualSalesTargetQty")
            ?.errors | getErrorMessage: translations.Quantity2
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" [ngClass]="
            !customerInformationForm.get(
              'customerTechnicalInfo.annualSalesTargetUom'
            )?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          ">
          <mat-label for="annualSalesTargetUom">
            {{ "IR_FORM.UnitOfMeasure" | translate }}
          </mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="annualSalesTargetUom"
            name="annualSalesTargetUom">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let unit of unitsOfMeasure" [value]="unit.idUom">
              {{ unit.name }}
            </mat-option>
          </mat-select>
          <button *ngIf="
              !isReadOnly &&
              !isLabManagerInternal &&
              customerInformationForm.get('customerTechnicalInfo.annualSalesTargetUom')?.value !== idMarketAnalysisProduct
            " type="button" class="only-icon-button primary" matSuffix (click)="showUomManagementDialog($event)">
            <svg-icon name="list_icon_2" class="w20 h20"></svg-icon>
          </button>
          <mat-error>
            {{
            customerInformationForm.get("customerTechnicalInfo.annualSalesTargetUom")
            ?.errors | getErrorMessage: translations.UnitOfMeasure
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="isIsp" class="section-card mt-30">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.Note" | translate }}</span>
      </div>
    </div>
    <div class="content" [formGroup]="noteForm">
      <div formArrayName="notes">
        <div class="row my-3">
          <span
            class="text-color col-12 fs-italic"
            style="font-size: 13px; margin: 0 auto; text-align: center"
            >{{ "IR_FORM.NoteDescription" | translate }}</span
          >
        </div>
        <div class="row" [formGroupName]="0">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label for="description">{{
              "IR_FORM.ReferentNote" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="description"
              name="description"
            ></textarea>
          </mat-form-field>
          <span class="col-12 text-color custom-hint fs-italic"
            >{{ "IR_FORM.ReferenceNoteDescr" | translate }}</span
          >
        </div>
        <div class="row" [formGroupName]="1">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label for="description"
              >{{ "IR_FORM.CustomerNote" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="description"
              name="description"
            ></textarea>
          </mat-form-field>
          <span class="col-12 text-color custom-hint fs-italic"
            >{{ "IR_FORM.CustomerNoteDescr" | translate }}</span
          >
        </div>
        <div class="row" [formGroupName]="2">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label for="description">{{
              "IR_FORM.ProductNote" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="description"
              name="description"
            ></textarea>
          </mat-form-field>
          <span class="col-12 text-color custom-hint fs-italic"
            >{{ "IR_FORM.ProductNoteDescr" | translate }}</span
          >
        </div>
        <div class="row" [formGroupName]="3">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label for="description">{{
              "IR_FORM.ManagerNote" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="description"
              name="description"
            ></textarea>
          </mat-form-field>
          <span class="col-12 text-color custom-hint fs-italic"
            >{{ "IR_FORM.ManagerNoteDescr" | translate }}</span
          >
        </div>
      </div>
    </div>
  </div> -->
  <div class="center-buttons mt-30" *ngIf="!isLabManagerInternal && !isReadOnly">
    <button type="submit" class="rounded-button primary-bg-button">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
<div class="mt-40">
  <button type="button" class="rounded-button primary-border-button m-0-auto" appScrollToTop>
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>